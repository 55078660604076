export enum ChatSearchParams {
  DialogId = 'dialogId',
  ConversationId = 'conversationId',
}

export const EmptyConversationId = 'empty';

export interface SampleQuestion {
  text: string;
  hotDegree?: 1 | 2 | 3;
}

export const commonQuestions: SampleQuestion[] = [
  { text: "TT门店收银口完美门店标准是什么？" },
  { text: "广州便利店的必分销清单？" },
  { text: "酸条的卖点有哪些？" },
  { text: "南区CVS完美门店图像" },
  { text: "天龙八步是什么？" },
  { text: "绿箭OTC是不是大卖场必分销SKU？" },
  { text: "无产品，有价签可以算作门店有分销吗?" },
  { text: "COT 分销单品AI识别不上怎么办？" },
  { text: "巧克力双层架放在主货架上，是算2层还是一层？" },
  { text: "巧克力融化了怎么办" },
  { text: "巧克力有白霜怎么办" },
  { text: "产品吃出异物（头发、塑料、石头）怎么办" },
  { text: "产品投诉流程怎样" },
  { text: "质检报告/警示检测报告/工厂证明怎么查" },  
  { text: "车辆出事故后如何操作？" },
  { text: "车辆出事故后联系哪个保险公司？" },
];

export const hotIssues: SampleQuestion[] = [
  { text: "彩虹糖加特林建议零售价是多少", hotDegree: 1 },
  { text: "彩虹糖加特林的内容物的类型和数量是什么", hotDegree: 1 },
  { text: "彩虹糖加特林能不能参与新春团购", hotDegree: 1 },
  { text: "彩虹糖加特林是自带PDQ（陈列工具）吗", hotDegree: 1 },
  { text: "彩虹糖加特林的卖点是什么", hotDegree: 1 },  
  { text: "今年CNY团购返利的坎级是多少" },
  { text: "今年CNY团购的积分兑换比例是多少" },
  { text: "今年CNY团购对比去年更新了什么活动产品" },  
  { text: "可可脂和代可可脂的区别" },
  { text: "2024新品", hotDegree: 2 },
  { text: "玛氏常用缩写有哪些？" },
  { text: "酸条的箱规是多少" },
  { text: "士力架35克的保质期是多长时间" },
];

// New: Hierarchical Topics
export interface Topic {
  id: string;
  name: string;
  subtopics?: Topic[];
  questions?: SampleQuestion[];
}

export const hierarchicalTopics: Topic[] = [
  {
    id: '1',
    name: '门店执行',
    subtopics: [
      {
        id: '1-1',
        name: '必分销产品',
        subtopics: [
          {
            id: '1-1-1',
            name: '绿箭OTC必分销',
            questions: [{ text: '绿箭OTC是不是大卖场必分销SKU？' }],
          },
          {
            id: '1-1-2',
            name: '广州CVS必分销',
            questions: [{ text: '广州便利店的必分销清单？' }],
          },
          {
            id: '1-1-3',
            name: 'TT金必分销',
            questions: [{ text: 'TT金门店必分销产品？' }],
          },   
          {
            id: '1-1-4',
            name: '大卖场必分销',
            questions: [{ text: '大卖场必分销清单有哪些？' }],
          },                     
        ],
      },
      {
        id: '1-2',
        name: '陈列指引',
        subtopics: [
          {
            id: '1-2-1',
            name: '南区CVS完美门店',
            questions: [{ text: '南区CVS完美门店图像' }],
          },
        ],
      },
      {
        id: '1-3',
        name: '陈列工具',
        subtopics: [
          {
            id: '1-3-1',
            name: '蓝绿双塔陈列',
            questions: [{ text: '蓝绿双塔的陈列图' }],
          },
        ],
      },      
      {
        id: '1-4',
        name: '考核标准',
        subtopics: [
          {
            id: '1-4-1',
            name: '巧克力双层架',
            questions: [{ text: '巧克力双层架放在主货架上，是算2层还是一层？' }],
          },          
        ],
      },          
      {
        id: '1-5',
        name: '拜访步骤',
        subtopics: [
          {
            id: '1-5-1',
            name: '天龙八步是什么',
            questions: [{ text: '天龙八步是什么？' }],
          }       
        ],
      },        
    ],
  },
  {
    id: '2',
    name: '安全&合规',
    subtopics: [
      {
        id: '2-1',
        name: '销售安全',
        subtopics: [
          {
            id: '2-1-1',
            name: '车辆事故的操作',
            questions: [
              { text: '车辆出事故后如何操作？' },
            ],
          },
          {
            id: '2-1-2',
            name: '车辆事故保险公司',
            questions: [
              { text: '车辆出事故后联系哪个保险公司？' },
            ],
          }                  
        ],
      },
      {
        id: '2-2',
        name: '销售合规',
        subtopics: [
          {
            id: '2-2-1',
            name: '什么是六真',
            questions: [
              { text: '什么是六真？' },
            ],
          },
          {
            id: '2-2-2',
            name: '无产品有价签',
            questions: [
              { text: '无产品，有价签可以算作门店有分销吗?' },
            ],
          },
          {
            id: '2-2-3',
            name: '没有头牌头卡',
            questions: [
              { text: ' 门店陈列架AI识别成功，但是实际上陈列架没有头牌头卡算不算有效陈列工具？' },
            ],
          }                  
        ],
      },      
    ],
  },
  {
    id: '3',
    name: '产品知识',
    subtopics: [
      {
        id: '3-1',
        name: '新品卖进',
        subtopics: [
          {
            id: '3-1-1',
            name: '酸条的卖点',
            questions: [{ text: '酸条的卖点有哪些？' }],
          },
          {
            id: '3-1-2',
            name: '2024新品',
            questions: [{ text: '2024新品' }],
          },          
        ],
      },
      {
        id: '3-2',
        name: '糖果',
        subtopics: [
          {
            id: '3-2-1',
            name: '彩虹糖果莓味图片',
            questions: [{ text: '彩虹糖9g果莓味的图片' }],
          },
          {
            id: '3-2-2',
            name: '彩虹糖9g保质期',
            questions: [{ text: '彩虹糖9g保质期' }],
          },          
        ],
      },      
      {
        id: '3-3',
        name: '薄荷糖 & 口香糖',
        subtopics: [
          {
            id: '3-3-1',
            name: '13克薄荷糖的箱规',
            questions: [{ text: '13克薄荷糖的箱规' }],
          },
          {
            id: '3-3-2',
            name: '吃口香糖的好处',
            questions: [{ text: '吃口香糖的好处' }],
          },    
          {
            id: '3-3-3',
            name: '绿箭OTC必分销',
            questions: [{ text: '绿箭OTC是不是大卖场必分销SKU？' }],
          },   
          {
            id: '3-3-4',
            name: '益达40粒零售价',
            questions: [{ text: '益达40粒零售价' }],
          },                             
        ],
      },         
      {
        id: '3-4',
        name: '巧克力',
        subtopics: [
          {
            id: '3-4-1',
            name: '吃巧克力的好处',
            questions: [{ text: '吃巧克力有哪些好处?' }],
          },
          {
            id: '3-4-2',
            name: '德芙42g小巧粒的价格',
            questions: [{ text: '德芙42g小巧粒的经销商价格是多少?' }],
          },
          {
            id: '3-4-3',
            name: '德芙43g奶香白条码',
            questions: [{ text: '德芙43克奶香白的条码?' }],
          },
          {
            id: '3-4-4',
            name: '士力架35g保质期',
            questions: [{ text: '士力架35克的保质期是多长时间' }],
          },
        ],
      },
    ],
  },
  {
    id: '4',
    name: '质量专栏',
    subtopics: [
      {
        id: '4-1',
        name: '温控管理',
        subtopics: [
          {
            id: '4-1-1',
            name: '巧克力融化处理',
            questions: [{ text: '巧克力融化了怎么办?' }],
          },
          {
            id: '4-1-2',
            name: '巧克力有白霜',
            questions: [{ text: '巧克力有白霜怎么办' }],
          },
        ],
      },
      {
        id: '4-2',
        name: '投诉流程',
        subtopics: [
          {
            id: '4-2-1',
            name: '产品吃出异物',
            questions: [{ text: '产品吃出异物（头发、塑料、石头）怎么办?' }],
          },
          {
            id: '4-2-2',
            name: '产品投诉流程',
            questions: [{ text: '产品投诉流程怎样?' }],
          },
        ],
      },
      {
        id: '4-3',
        name: '质检报告',
        subtopics: [
          {
            id: '4-3-1',
            name: '质检报告查询',
            questions: [{ text: '质检报告/警示检测报告/工厂证明怎么查' }],
          },
        ],
      },
    ],
  },
  {
    id: '5',
    name: '常见系统',
    subtopics: [
      {
        id: '5-1',
        name: 'COT系统',
        subtopics: [
          {
            id: '5-1-1',
            name: 'AI识别不准确怎么办',
            questions: [{ text: 'AI识别结果不准确怎么办？' }],
          },
        ],
      },
      {
        id: '5-2',
        name: '易下单',
        subtopics: [
          {
            id: '5-2-1',
            name: '订单下错处理',
            questions: [{ text: '易下单订单下错，销售无法当场修正订单怎么处理?' }],
          },
          {
            id: '5-2-2',
            name: '非经销商供货产品',
            questions: [{ text: 'NonKA门店中有部分产品非经销商供货，这部分产品能否纳入易下单MH订单分销考核计算?' }],
          },
        ],
      },
    ],
  }
];